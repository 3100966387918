import type { Tooltip, TooltipBase } from './types'
import { decode, withoutTrailingSlash } from 'ufo'

export const resolveTooltip = (
  defaultOptions?: TooltipBase,
  options?: Tooltip,
) => {
  if (defaultOptions || options) {
    return {
      ...(defaultOptions ?? {}),
      ...(options ?? {}),
    }
  }
}

export const isRouteActive = (p1: string, p2: string) => {
  const path1 = decode(withoutTrailingSlash(p1))
  const path2 = decode(withoutTrailingSlash(p2))
  return path1 === path2 || path2.startsWith(path1)
}
